import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

const TimelineData = [
  {
    id: '1',
    date: 'Analiza',
    title: 'Investigăm și evaluăm nevoile afacerii dumneavoastră',
    description:
      'În această etapă, ne concentrăm pe înțelegerea nevoilor și așteptărilor dumneavoastră pentru a oferi un fundament solid soluțiilor noastre.',
    image: 'analysis',
    workingStep: [
      {
        stepTitle: 'Evaluare detaliată',
        stepDescription:
          'Realizăm o evaluare detaliată a proceselor curente, identificăm provocările și analizăm cerințele specifice ale industriei dumneavoastră.'
      },
      {
        stepTitle: 'Identificare oportunități',
        stepDescription:
          'Identificăm oportunitățile de îmbunătățire și aliniem propunerile noastre cu obiectivele strategice ale afacerii dumneavoastră.'
      }
    ]
  },
  {
    id: '2',
    date: 'Solutia',
    title: 'Cream o soluție adaptată nevoilor dumneavoastră',
    description:
      'În baza discuției inițiale noi vom crea o soluție personalizată pentru dumneavoastră.',
    image: 'solution',
    workingStep: [
      {
        stepTitle: 'Creăm soluția',
        stepDescription:
          'Pregătim o soluție personalizată pentru dumneavoastră având în vedere infrastructura și nevoile specifice.'
      },
      {
        stepTitle: 'Prezentare Plan',
        stepDescription:
          'Vă prezentăm un plan detaliat cu etapele, costurile și timpul necesar pentru implementare, asigurându-vă de sustenabilitatea și scalabilitatea soluțiilor propuse.'
      }
    ]
  },
  {
    id: '3',
    date: 'Dezvoltarea',
    title: 'Implementăm soluția propusă',
    description:
      'Punem în aplicare planul de soluții propus, colaborând strâns cu dumneavoastră pentru a asigura o tranziție fluidă și o implementare reușită.',
    image: 'develop',
    workingStep: [
      {
        stepTitle: 'Implementare Soluții',
        stepDescription:
          'Colaborăm pentru a implementa soluțiile de digitalizare și oferim consultanța necesară pentru a asigura o tranziție fluidă.'
      },
      {
        stepTitle: 'Asigurarea Calității',
        stepDescription:
          'Ne dedicăm finalizării proiectului la timp și în conformitate cu standardele de calitate convenite, urmărind satisfacția și succesul afacerii dumneavoastră.'
      }
    ]
  }
]

const TimelineOne = () => {
  return (
    <div className='rn-timeline-wrapper timeline-style-one position-relative'>
      <div className='timeline-line'></div>
      {/* Start Single Timeline  */}

      {TimelineData.map((data, index) => (
        <div className='single-timeline mt--50' key={index}>
          <div className='timeline-dot'>
            <div className='time-line-circle'></div>
          </div>
          <div className='single-content'>
            <div className='inner'>
              <div className='row row--30 align-items-center'>
                <div className='order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40'>
                  <div className='content'>
                    <ScrollAnimation
                      animateIn='fadeInUp'
                      animateOut='fadeInOut'
                      animateOnce={true}
                    >
                      <span className='date-of-timeline'>{data.date}</span>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn='fadeInUp'
                      animateOut='fadeInOut'
                      animateOnce={true}
                    >
                      <h2 className='title'>{data.title}</h2>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn='fadeInUp'
                      animateOut='fadeInOut'
                      animateOnce={true}
                    >
                      <p className='description'>{data.description}</p>
                    </ScrollAnimation>

                    <div className='row row--30'>
                      {data.workingStep.map((data, index) => (
                        <div className='col-lg-6 col-md-6 col-12' key={index}>
                          <div className='working-list'>
                            <ScrollAnimation
                              animateIn='fadeInUp'
                              animateOut='fadeInOut'
                              animateOnce={true}
                            >
                              <h5 className='working-title'>
                                {data.stepTitle}
                              </h5>
                            </ScrollAnimation>

                            <ScrollAnimation
                              animateIn='fadeInUp'
                              animateOut='fadeInOut'
                              animateOnce={true}
                            >
                              <p>{data.stepDescription}</p>
                            </ScrollAnimation>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className='read-morebtn'>
                      <ScrollAnimation
                        animateIn='fadeInUp'
                        animateOut='fadeInOut'
                        animateOnce={true}
                      >
                        <a
                          className='btn-default btn-large round'
                          href='/ro/contact'
                        >
                          <span>Începeți azi</span>
                        </a>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
                <div className='order-1 order-lg-2 col-lg-6'>
                  <div className='thumbnail'>
                    <img
                      className='w-100'
                      src={`/images/timeline/${data.image}.jpg`}
                      alt='Corporate React Template'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* End Single Timeline  */}
    </div>
  )
}

export default TimelineOne
