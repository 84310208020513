
import urlLocale from "./urlLocale";

const sleepRedirect = (milliseconds) => {
  console.log("sleeping on Splash")
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

const SplashRedirect = () => {
  sleepRedirect(5000).then(() => {

    var currentUrl = window.location.pathname;
    console.log("we made it here: "+currentUrl);
    if (currentUrl === urlLocale()) {
      window.location.href = `${urlLocale()}/home`;

    }
  })
};


export default SplashRedirect;
