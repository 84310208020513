const userlocale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language

const urlLocale = () => {
  var fixed = true
  var lang
  if (fixed) {
    return process.env.PUBLIC_URL + '/ro'
  } else {
    if (userlocale === 'ro') {
      lang = process.env.PUBLIC_URL + '/ro'
    } else {
      lang = process.env.PUBLIC_URL + '/en'
    }
    console.log(`lang is:${lang}`)
    return lang
  }
}
export default urlLocale
