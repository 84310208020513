import React from 'react'
import { Link } from 'react-router-dom'
import urlLocale from '../../utils/urlLocale'

const Nav = () => {
  if (urlLocale() === '/ro') {
    return (
      <ul className='mainmenu'>
        <li className='has-droupdown'>
          <Link to={`${urlLocale()}/home`}>Acasă</Link>
        </li>
        <li className='has-droupdown'>
          <Link to={`${urlLocale()}/contact`}>Contact</Link>
        </li>
        <li>
          <Link to='/ro/desprenoi'>Despre Mine</Link>
        </li>
      </ul>
    )
  } else {
    return (
      <ul className='mainmenu'>
        <li className='has-droupdown'>
          <Link to={`${urlLocale()}/home`}>Acasă</Link>
        </li>
        <li className='has-droupdown'>
          <Link to={`${urlLocale()}/contact`}>Contact</Link>
        </li>
        <li>
          <Link to='/ro/aboutus'>Despre Mine</Link>
        </li>
      </ul>
    )
  }
}
export default Nav
