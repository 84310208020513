import React from 'react'
import SEO from "../common/SEO";
import Separator from "../elements/separator/Separator";
import HeaderRo from '../common/header/HeaderRo';
import SplashRedirect from '../utils/sleep';
import Copyright from '../common/footer/Copyright';

const SplashRo = () => {
    SplashRedirect();
    
    return (
        <>

            <SEO title="" />
            <main className="page-wrapper">
                {/*<HeaderTopNews />*/}
                <HeaderRo btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="demo-slider-area slider-area bg-transparent slider-style-1 pb--100 pt--70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <div className="react-image mb--20">
                                        <img src="./images/logo/logo-2x.png" alt="Doob React" />
                                    </div>
                                    <h1 className="title display-two">

                                        <span className="theme-gradient">Consultanță IT</span>
                                        <br /> și {" "}
                                        <br /><span className="theme-gradient">Digitalizare.</span>


                                    </h1>
                                    <p className="description">Ne ajutam clienții să ajunga in viitor.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />


                <Copyright />
            </main>

        </>
    )
}

export default SplashRo;