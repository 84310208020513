import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        title: "Cunoștințe",
        description: "În cadrul călătoriei mele profesionale, am dobândit o serie de skill-uri esențiale, precum:\n\nJavaScript (React, Node.js), HTML5 & CSS3, Baze de date SQL & NoSQL, Versionare cu Git, Responsive Web Design, infrastructuri Azure si M365, Framework-uri și metodologii Agile.",
    },
    {
        id: "2",
        title: "Proiecte",
        description: "Fiecare sarcină sau proiect pe care l-am preluat a fost o oportunitate de a mă dezvolta. Am colaborat cu echipe diversificate și am lucrat în diferite medii, adaptându-mă nevoilor specifice ale fiecărei situații.",
    },
    {
        id: "3",
        title: "Soluții",
        description: "Rezolvarea problemelor este esența activității mele. Am dezvoltat abilitatea de a analiza situații complexe, de a identifica soluții optime și de a le implementa într-un mod eficient pentru a obține rezultatele dorite.",
    },
    {
        id: "4",
        title: "Proces",
        description: "Procesul meu de lucru implică o planificare riguroasă, comunicare deschisă și o abordare centrată pe client. Aceasta asigură că rezultatele pe care le ofer sunt în concordanță cu așteptările și obiectivele stabilite.",
    },
]


const TimelineTwo = ({classVar}) => {
    return (
        <div className="timeline-style-two bg-color-blackest">
            <div className="row row--0">
                {/* Start Single Progress */}
                {TimelineData.map((data , index) =>(
                    <div className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`} key={index}>
                        <div className="rn-timeline">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h6 className="title">{data.title}</h6>
                            </ScrollAnimation>
                            <div className="progress-line">
                                <div className="line-inner"></div>
                            </div>
                            <div className="progress-dot">
                                <div className="dot-level">
                                    <div className="dot-inner"></div>
                                </div>
                            </div>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">{data.description}</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                ))}
                {/* End Single Progress */}
            </div>
        </div>
    )
}
export default TimelineTwo;