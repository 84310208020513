import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import Splash from "./pages/Splash";
import HomeDefault from "./pages/HomeDefault";


import CategoryList from "./pages/CategoryList";
import TagList from "./pages/TagList";
import Author from "./pages/Author";
import AboutUs from "./pages/AboutUs";
// Ro Pages import Here
import HomeDefaultRo from "./pages/HomeDefaultRo";
import SplashRo from './pages/SplashRo';
import AboutUsRo from "./pages/AboutUsRo";
import ContactRo from "./elements/contact/ContactRo";
import PortfolioRo from "./elements/portfolio/PortfolioRo";
import BlogListViewRo from "./components/blog/Stiri";
import PricingRo from "./elements/pricing/PricingRo";

// Elements import Here 

import Elements from "./elements/elements/Elements";
import Button from "./elements/button/Button";
import Service from "./elements/service/Service";
import CounterUp from "./elements/counterup/CounterUp";
import Progressbar from "./elements/progressbar/Progressbar";

import Portfolio from "./elements/portfolio/Portfolio";
import PortfolioGrid from "./elements/portfolio/PortfolioGrid";
import PortfolioBoxLayout from "./elements/portfolio/PortfolioBoxLayout";
import PortfolioDetails from "./pages/PortfolioDetails";
import Social from "./elements/social/Social";
import Team from "./elements/team/Team";
import Timeline from "./elements/timeline/Timeline";
import Testimonial from "./elements/testimonial/Testimonial";
import RnAccordion from "./elements/accordion/RnAccordion";
import Tab from "./elements/tab/Tab";
import Pricing from "./elements/pricing/Pricing";
import Split from "./elements/split/Split";
import CalltoAction from "./elements/calltoaction/CalltoAction";
import Video from "./elements/video/Video";
import Gallery from "./elements/gallery/Gallery";
import Contact from "./elements/contact/Contact";
import Brand from "./elements/brand/Brand";
import AdvanceTab from "./elements/advancetab/AdvanceTab";
import AdvancePricing from "./elements/advancePricing/AdvancePricing";


// Blog import Here
import BlogListView from "./components/blog/BlogListView";
import BlogDetails from "./pages/BlogDetails";
import Error from "./pages/Error";


// Import Css Here 
import './assets/scss/style.scss';

//Deternime Language
import urlLocale from './utils/urlLocale';

const App = () => {

    return (

        <Router>
            <PageScrollTop>
                <Switch>
                    {/* Romanian Route */}
                    <Route exact path={`${process.env.PUBLIC_URL + "/"}`}>
                        <Redirect to={urlLocale()} />
                    </Route>
                    <Route path={`${urlLocale()}`} exact component={SplashRo} />
                    <Route path={`${urlLocale() + "/home"}`} exact component={HomeDefaultRo} />
                    <Route path={`${urlLocale() + "/stiri"}`} exact component={BlogListViewRo} />
                    <Route path={`${urlLocale() + "/portofoliu"}`} exact component={PortfolioRo} />
                    <Route path={`${urlLocale() + "/preturi"}`} exact component={PricingRo} />
                    <Route path={`${urlLocale() + "/contact"}`} exact component={ContactRo} />
                    <Route path={`${urlLocale() + "/desprenoi"}`} exact component={AboutUsRo} />

                    {/* End of Romanian Route */}

                    <Route path={`${urlLocale()}`} exact component={Splash} />
                    <Route path={`${urlLocale() + "/home"}`} exact component={HomeDefault} />

                    {/* Blog Part  */}

                    <Route path={`${urlLocale() + "/news"}`} exact component={BlogListView} />
                    <Route path={`${urlLocale() + "/blog-details/:id"}`} exact component={BlogDetails} />
                    <Route path={`${urlLocale() + "/category/:slug"}`} exact component={CategoryList} />
                    <Route path={`${urlLocale() + "/archive/:slug"}`} exact component={Author} />
                    <Route path={`${urlLocale() + "/tag/:slug"}`} exact component={TagList} />

                    <Route path={`${urlLocale() + "/elements"}`} exact component={Elements} />
                    <Route path={`${urlLocale() + "/button"}`} exact component={Button} />
                    <Route path={`${urlLocale() + "/service"}`} exact component={Service} />
                    <Route path={`${urlLocale() + "/counter"}`} exact component={CounterUp} />
                    <Route path={`${urlLocale() + "/progressbar"}`} exact component={Progressbar} />
                    <Route path={`${urlLocale() + "/social-share"}`} exact component={Social} />
                    <Route path={`${urlLocale() + "/team"}`} exact component={Team} />
                    <Route path={`${urlLocale() + "/testimonial"}`} exact component={Testimonial} />
                    <Route path={`${urlLocale() + "/timeline"}`} exact component={Timeline} />
                    <Route path={`${urlLocale() + "/accordion"}`} exact component={RnAccordion} />
                    <Route path={`${urlLocale() + "/tab"}`} exact component={Tab} />
                    <Route path={`${urlLocale() + "/pricing"}`} exact component={Pricing} />
                    <Route path={`${urlLocale() + "/split"}`} exact component={Split} />
                    <Route path={`${urlLocale() + "/call-to-action"}`} exact component={CalltoAction} />
                    <Route path={`${urlLocale() + "/video-popup"}`} exact component={Video} />
                    <Route path={`${urlLocale() + "/gallery"}`} exact component={Gallery} />
                    <Route path={`${urlLocale() + "/contact"}`} exact component={Contact} />
                    <Route path={`${urlLocale() + "/brand"}`} exact component={Brand} />
                    <Route path={`${urlLocale() + "/advance-tab"}`} exact component={AdvanceTab} />
                    <Route path={`${urlLocale() + "/advance-pricing"}`} exact component={AdvancePricing} />
                    <Route path={`${urlLocale() + "/about-us"}`} exact component={AboutUs} />

                    <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error} />

                    <Route path={`${urlLocale() + "/portfolio"}`} exact component={Portfolio} />
                    <Route path={`${urlLocale() + "/portfolio-grid-layout"}`} exact component={PortfolioGrid} />
                    <Route path={`${urlLocale() + "/portfolio-box-layout"}`} exact component={PortfolioBoxLayout} />
                    <Route path={`${urlLocale() + "/portfolio-details/:id"}`} exact component={PortfolioDetails} />


                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
