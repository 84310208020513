import React from 'react'
import HeaderOne from './header/HeaderOne'
//import FooterTwo from './footer/FooterTwo'
import Copyright from './footer/Copyright'
import HeaderRo from './header/HeaderRo'

import urlLocale from '../utils/urlLocale'

const Layout = ({ children }) => {
  if (urlLocale() === '/ro') {
    return (
      <>
        <main className='page-wrapper'>
          {/*<HeaderTopNews />*/}
          <HeaderRo
            btnStyle='btn-small round btn-icon'
            HeaderSTyle='header-not-transparent'
          />
          {children}
          <Copyright />
        </main>
      </>
    )
  } else {
    return (
      <>
        <main className='page-wrapper'>
          {/*<HeaderTopNews />*/}
          <HeaderOne
            btnStyle='btn-small round btn-icon'
            HeaderSTyle='header-not-transparent'
          />
          {children}
          <Copyright />
        </main>
      </>
    )
  }
}
export default Layout
