import React from 'react';
import { FiCheck } from "react-icons/fi";



const AboutFour = ({image}) => {
    return (
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6">
                        <div className="thumbnail">
                            <img className="w-100" src="/images/logo/logo-2x.png" alt="About Images" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title">Specializat în dezvoltare web <br /> pentru <strong>companii mici și mijlocii.</strong></h3>
                                <ul className="feature-list">
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Abordare personalizată</h4>
                                            <p className="text">Înțeleg nevoile unice ale fiecărei companii și ofer soluții adaptate.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Pasionat de tehnologie</h4>
                                            <p className="text">Mă asigur că rămân la curent cu cele mai recente tendințe și tehnologii.</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="about-btn mt--30">
                                    <a className="btn-default" target="_blank" href="mailto:contact@gionita.ro" rel="noreferrer">Hai să ne cunoaștem</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFour
