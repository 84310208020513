import React from 'react'
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderRo from '../common/header/HeaderRo';
import Copyright from '../common/footer/Copyright';
import ServiceOne from '../elements/service/ServiceOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import TimelineOne from "../elements/timeline/TimelineOne";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";



const HomeDefaultRo = () => {

    return (
        <>
            <SEO title="Business Consulting" />
            <main className="page-wrapper">
                {/*<HeaderTopNews />*/}
                <HeaderRo  btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-3.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <span className="subtitle" >DIGITAL CONSULTING</span>
                                    <h1 className="theme-gradient">Georgian Ionita <span>Consultanta IT și digitalizare</span>.</h1>
                                    <p className="description">Ne ajutam clienții să ajunga in viitor.</p>
                                    <div className="button-group">
                                        <a className="btn-default btn-medium round btn-icon" href="#Proces">Vezi ce oferim <i className="icon"><FiArrowRight /></i></a>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="./contact">Contact Us <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gapBottom pt--100">
                    <div className="container">
                        <ServiceOne
                            serviceStyle = "service__style--1 icon-circle-style"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                <Separator />
                {/* Start Timeline Area  */}
                <div className="rwt-timeline-area rn-section-gap" id='Proces'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Procesul de lucru"
                                        title = "Cum va ajutam?"
                                        description = "Va oferim servicii de consultanta pentru a va aduce afacerea in viitor."
                                    />
                            </div>
                        </div>
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                                <TimelineOne />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Timeline Area  */}

                <Separator />


                {/* Start Elements Area  */}
                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div>
                {/* End Elements Area  */}

                <Copyright />

            </main>

        </>
    )
}
export default HomeDefaultRo

