import React from 'react'
import SEO from '../common/SEO'
import HeaderRo from '../common/header/HeaderRo'
import AboutFour from '../elements/about/AboutFour'
import ServiceOne from '../elements/service/ServiceOne'
import SectionTitle from '../elements/sectionTitle/SectionTitle'
import CounterUpFour from '../elements/counterup/CounterUpFour'
import TimelineTwo from '../elements/timeline/TimelineTwo'
import Separator from '../elements/separator/Separator'
import Copyright from '../common/footer/Copyright'
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive'

const AboutUsRo = () => {
  return (
    <>
      <SEO title='Despre mine' />
      <main className='page-wrapper'>
        {/*<HeaderTopNews />*/}
        <HeaderRo
          btnStyle='btn-small round btn-icon'
          HeaderSTyle='header-transparent'
        />
        {/* Start Slider Area  */}
        <div
          className='slider-area slider-style-1 height-850 bg_image'
          data-black-overlay='7'
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-3.jpg)`
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner pt--80 text-center'>
                  <div>
                    <h3 className='rn-sub-badge'>
                      <span className='theme-gradient'>Despre mine</span>
                    </h3>
                  </div>
                  <h1 className='title display-one'>
                    Pasiune, Determinare și Expertiză în Dezvoltare Web
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className='service-area rn-section-gapTop'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='content'>
                  <h3 className='title'>
                    Transform viziunile în realitate prin dezvoltare web.
                  </h3>
                </div>
              </div>
              <div className='col-lg-6'>
                <p className='mb--10'>
                  Având o bază puternică în limbajele de programare și o atenție
                  meticuloasă la detalii, dezvolt aplicații web și nu numai,
                  care sunt atât eficiente, cât și intuitive. Cu o experiență
                  recunoscută, având ocazia să lucrez pentru unele dintre cele
                  mai prestigioase companii globale, am o dorință constantă de a
                  învăța și sunt mereu pregătit pentru noi provocări.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />

        {/* Start Service Area  */}
        <div className='rn-service-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Ce facem pentru dumneavoastra?'
                  title='Serviciile pe care vi le oferim'
                  description=''
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle='service__style--1 bg-color-blackest radius mt--25'
              textAlign='text-center'
            />
          </div>
        </div>
        {/* End Service Area  */}

        <div className='rwt-timeline-area rn-section-gapBottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <TimelineTwo classVar='no-gradient' />
              </div>
            </div>
          </div>
        </div>

        <Separator />

        <AboutFour image='./images/about/contact-image.jpg' />

        {/* Start Elements Area  */}
        <div className='rwt-counterup-area pb--100'>
          <div className='container mt_dec--30'>
            <CounterUpFour
              column='col-lg-3 col-md-6 col-sm-6 col-12'
              counterStyle='counter-style-4'
              textALign='text-center'
            />
          </div>
        </div>
        {/* End Elements Area  */}

        <Separator />
        <CalltoActionFive />
        <Separator />
        <Copyright />
      </main>
    </>
  )
}

export default AboutUsRo
