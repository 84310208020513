import React from 'react'
import { Link } from "react-router-dom";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopNews from '../common/header/HeaderTopNews';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterThree from '../common/footer/FooterThree';
import { FiArrowDown } from "react-icons/fi";
import SplashRedirect from '../utils/sleep';

const Splash = () => {
    SplashRedirect();

    return (
        <>

            <SEO title="" />
            <main className="page-wrapper">
                {/*<HeaderTopNews />*/}
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="demo-slider-area slider-area bg-transparent slider-style-1 pb--100 pt--70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <div className="react-image mb--20">
                                        <img src="./images/logo/logo-2x.png" alt="Doob React" />
                                    </div>
                                    <h1 className="title display-two">

                                        <span className="theme-gradient">IT Consultancy</span>
                                        <br /> and {" "}
                                        <br /><span className="theme-gradient">Digitalization.</span>


                                    </h1>
                                    <p className="description">We help our costumers to reach tommorow.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />


                <FooterThree />
            </main>

        </>
    )
}

export default Splash;